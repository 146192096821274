import { OpenCascadeInstance, TopoDS_Shape } from "opencascade.js";

import {
  BRepMesh_IncrementalMesh_2,
  Handle_Poly_Triangulation,
  Poly_Connect_2,
  TopAbs_ShapeEnum,
  TopLoc_Location_1,
  TopoDS_Face,
} from "opencascade.js";
import * as THREE from "three";
import { mergeGeometries } from "three/examples/jsm/utils/BufferGeometryUtils";
import { SurfaceTypeEnum } from "./types";


export default function convertShapeToThree(
  openCascade: OpenCascadeInstance,
  shape: TopoDS_Shape,
  singleMesh: boolean = false
): {
  geo: THREE.BufferGeometry | THREE.BufferGeometry[];
  faceTypeList: SurfaceTypeEnum[];
} {
  const geometries: THREE.BufferGeometry[] = [];
  const faceTypeList: SurfaceTypeEnum[] = [];
  const ExpFace = new openCascade.TopExp_Explorer_1();
  for (
    ExpFace.Init(
      shape,
      openCascade.TopAbs_ShapeEnum.TopAbs_FACE as TopAbs_ShapeEnum,
      openCascade.TopAbs_ShapeEnum.TopAbs_SHAPE as TopAbs_ShapeEnum
    );
    ExpFace.More();
    ExpFace.Next()
  ) {
    // Downcasting
    const myShape = ExpFace.Current();
    const myFace = openCascade.TopoDS.Face_1(myShape);
    generateThreeBufferGeometry({
      oc: openCascade,
      shape: myShape,
      face: myFace,
      singleMesh,
      geometries,
    });
    faceTypeList.push(checkFaceType(openCascade, myFace));
    cleanUpFaceData({ face: myFace, shape: myShape });
  }

  ExpFace.delete();
  if (singleMesh) {
    const geometry: THREE.BufferGeometry = mergeGeometries(geometries);
    return { geo: geometry, faceTypeList };
  }
  return { geo: geometries, faceTypeList };
}

export function generateThreeBufferGeometry(input: {
  oc: OpenCascadeInstance;
  shape: TopoDS_Shape;
  face: TopoDS_Face;
  singleMesh: boolean;
  geometries: THREE.BufferGeometry[];
}) {
  const { oc, shape, face, singleMesh, geometries } = input;
  const { vertices, normals, indices, pc, aLocation, myT, inc } =
    triangulateFace(oc, face);
  createThreeGeoFromTriangles(vertices, indices, normals, geometries);
  cleanUpTriangleData({
    pc,
    aLocation,
    myT,
    inc,
    face,
    shape,
  });
}

function checkFaceType(oc: OpenCascadeInstance, face: TopoDS_Face) {
  const surfaceType = new oc.BRepAdaptor_Surface_2(face, true).GetType();
  switch (surfaceType) {
    case oc.GeomAbs_SurfaceType.GeomAbs_Plane: {
      return SurfaceTypeEnum.GeomAbs_Plane;
    }
    case oc.GeomAbs_SurfaceType.GeomAbs_Cylinder: {
      return SurfaceTypeEnum.GeomAbs_Cylinder;
    }
    case oc.GeomAbs_SurfaceType.GeomAbs_Cone: {
      return SurfaceTypeEnum.GeomAbs_Cone;
    }
    case oc.GeomAbs_SurfaceType.GeomAbs_Sphere: {
      return SurfaceTypeEnum.GeomAbs_Sphere;
    }
    case oc.GeomAbs_SurfaceType.GeomAbs_Torus: {
      return SurfaceTypeEnum.GeomAbs_Torus;
    }
    case oc.GeomAbs_SurfaceType.GeomAbs_BezierSurface: {
      return SurfaceTypeEnum.GeomAbs_BezierSurface;
    }
    case oc.GeomAbs_SurfaceType.GeomAbs_BSplineSurface: {
      return SurfaceTypeEnum.GeomAbs_BSplineSurface;
    }
    case oc.GeomAbs_SurfaceType.GeomAbs_SurfaceOfRevolution: {
      return SurfaceTypeEnum.GeomAbs_SurfaceOfRevolution;
    }
    case oc.GeomAbs_SurfaceType.GeomAbs_SurfaceOfExtrusion: {
      return SurfaceTypeEnum.GeomAbs_SurfaceOfExtrusion;
    }
    case oc.GeomAbs_SurfaceType.GeomAbs_OffsetSurface: {
      return SurfaceTypeEnum.GeomAbs_OffsetSurface;
    }
    case oc.GeomAbs_SurfaceType.GeomAbs_OtherSurface: {
      return SurfaceTypeEnum.GeomAbs_OtherSurface;
    }
    default: {
      throw new Error("Invalid Surface Type");
    }
  }
}

function triangulateFace(oc: OpenCascadeInstance, face: TopoDS_Face) {
  let inc;
  try {
    //in case some of the faces can not been visualized
    inc = new oc.BRepMesh_IncrementalMesh_2(face, 0.1, false, 0.5, false);
  } catch (e) {
    console.error("face visualizing failed");
    throw new Error("face visualizing failed");
    // continue;
  }

  const aLocation = new oc.TopLoc_Location_1();
  const myT = oc.BRep_Tool.Triangulation(
    face,
    aLocation,
    0 /* == Poly_MeshPurpose_NONE */
  );
  if (myT.IsNull()) {
    throw new Error("face has no triangulation");
    // continue;
  }

  const pc = new oc.Poly_Connect_2(myT);
  const triangulation = myT.get();

  const vertices = new Float32Array(triangulation.NbNodes() * 3);

  // write vertex buffer
  for (let i = 1; i <= triangulation.NbNodes(); i++) {
    const t1 = aLocation.Transformation();
    const p = triangulation.Node(i);
    const p1 = p.Transformed(t1);
    vertices[3 * (i - 1)] = p1.X();
    vertices[3 * (i - 1) + 1] = p1.Y();
    vertices[3 * (i - 1) + 2] = p1.Z();
    p.delete();
    t1.delete();
    p1.delete();
  }

  // write normal buffer
  const myNormal = new oc.TColgp_Array1OfDir_2(1, triangulation.NbNodes());
  oc.StdPrs_ToolTriangulatedShape.Normal(face, pc, myNormal);

  const normals = new Float32Array(myNormal.Length() * 3);
  for (let i = myNormal.Lower(); i <= myNormal.Upper(); i++) {
    const t1 = aLocation.Transformation();
    const d1 = myNormal.Value(i);
    const d = d1.Transformed(t1);

    normals[3 * (i - 1)] = d.X();
    normals[3 * (i - 1) + 1] = d.Y();
    normals[3 * (i - 1) + 2] = d.Z();

    t1.delete();
    d1.delete();
    d.delete();
  }

  myNormal.delete();

  // write triangle buffer
  const orient = face.Orientation_1();
  const triangles = myT.get().Triangles();
  let indices;
  const triLength = triangles.Length() * 3;
  if (triLength > 65535) indices = new Uint32Array(triLength);
  else indices = new Uint16Array(triLength);

  for (let nt = 1; nt <= myT.get().NbTriangles(); nt++) {
    const t = triangles.Value(nt);
    let n1 = t.Value(1);
    let n2 = t.Value(2);
    const n3 = t.Value(3);
    if (orient !== oc.TopAbs_Orientation.TopAbs_FORWARD) {
      const tmp = n1;
      n1 = n2;
      n2 = tmp;
    }

    indices[3 * (nt - 1)] = n1 - 1;
    indices[3 * (nt - 1) + 1] = n2 - 1;
    indices[3 * (nt - 1) + 2] = n3 - 1;
    t.delete();
  }
  triangles.delete();

  return {
    vertices,
    normals,
    indices,
    pc,
    aLocation,
    myT,
    inc,
  };
}

function createThreeGeoFromTriangles(
  vertices: Float32Array,
  indices: Uint16Array | Uint32Array,
  normals: Float32Array,
  geometries: THREE.BufferGeometry[]
) {
  const geometry = new THREE.BufferGeometry();
  geometry.setAttribute("position", new THREE.BufferAttribute(vertices, 3));
  geometry.setAttribute("normal", new THREE.BufferAttribute(normals, 3));

  geometry.setIndex(new THREE.BufferAttribute(indices, 1));
  geometries.push(geometry);
}

function cleanUpTriangleData(input: {
  pc: Poly_Connect_2;
  aLocation: TopLoc_Location_1;
  myT: Handle_Poly_Triangulation;
  inc: BRepMesh_IncrementalMesh_2;
  face: TopoDS_Face;
  shape: TopoDS_Shape;
}) {
  const { pc, aLocation, myT, inc, face, shape } = input;
  pc.delete();
  aLocation.delete();
  myT.delete();
  inc.delete();
}

export function cleanUpFaceData(input: { face: TopoDS_Face; shape: TopoDS_Shape }) {
  const { face, shape } = input;
  face.delete();
  shape.delete();
}

// ----------- OLDER STUFF ----------- //

function OCCTopoShapeToUrl(oc: OpenCascadeInstance, shape: TopoDS_Shape) {
    // Create a document and add our shape
    const docHandle = new oc.Handle_TDocStd_Document_2(
      new oc.TDocStd_Document(new oc.TCollection_ExtendedString_1())
    );
    const shapeTool = oc.XCAFDoc_DocumentTool.ShapeTool(
      docHandle.get().Main()
    ).get();
    shapeTool.SetShape(shapeTool.NewShape(), shape);
  
    // Tell OpenCascade that we want our shape to get meshed
    new oc.BRepMesh_IncrementalMesh_2(shape, 0.1, false, 0.1, false);
  
    // Export a STL file (this will also perform the meshing)
    const stlWriter = new oc.StlAPI_Writer();
    stlWriter.Write(shape, "./file.stl", new oc.Message_ProgressRange_1());
  
    // Read the GLB file from the virtual file system
    const stlFile = oc.FS.readFile("./file.stl", { encoding: "binary" });
  
    return URL.createObjectURL(
      new Blob([stlFile.buffer], { type: "model/stl-binary" })
    );
  }

// export const ConvertStepToSTL = async (
//   oc: OpenCascadeInstance,
//   file: Blob
// ): Promise<string> => {
//   return await loadFileAsync(file).then(async (fileText) => {
//     const fileType = "step";
//     // Writes the uploaded file to Emscripten's Virtual Filesystem
//     oc.FS.createDataFile("/", `file.${fileType}`, fileText, true, true, true);

//     // Choose the correct OpenCascade file parsers to read the CAD file
//     let reader = null;
//     if (fileType === "step") {
//       reader = new oc.STEPControl_Reader_1();
//     } else if (fileType === "iges") {
//       reader = new oc.IGESControl_Reader_1();
//     } else {
//       console.error("opencascade.js can't parse this extension! (yet)");
//     }

//     if (reader) {
//       const readResult = reader.ReadFile(`file.${fileType}`); // Read the file
//       if (readResult === oc.IFSelect_ReturnStatus.IFSelect_RetDone) {
//         // console.log("file loaded successfully! Converting to OCC now...");
//         const numRootsTransferred = reader.TransferRoots(
//           new oc.Message_ProgressRange_1()
//         ); // Translate all transferable roots to OpenCascade
//         const stepShape = reader.OneShape(); // Obtain the results of translation in one OCCT shape
//         const stlURL = OCCTopoShapeToUrl(oc, stepShape);
//         // Remove the file when we're done (otherwise we run into errors on reupload)
//         oc.FS.unlink(`/file.${fileType}`);
//         return stlURL;
//       } else {
//         console.error("Something in OCCT went wrong trying to read ");
//       }
//     }
//     throw new Error("Something went wrong trying to read the file");
//   });
// };

// export const ConvertStepToThreeMesh = async (
//   oc: OpenCascadeInstance,
//   file: Blob,
//   singleMesh: boolean = false
// ): Promise<THREE.Mesh | THREE.Mesh[]> => {
//   return await loadFileAsync(file).then(async (fileText) => {
//     const fileType = "step";
//     // Writes the uploaded file to Emscripten's Virtual Filesystem
//     oc.FS.createDataFile("/", `file.${fileType}`, fileText, true, true, true);

//     // Choose the correct OpenCascade file parsers to read the CAD file
//     let reader = null;
//     if (fileType === "step") {
//       reader = new oc.STEPControl_Reader_1();
//     } else if (fileType === "iges") {
//       reader = new oc.IGESControl_Reader_1();
//     } else {
//       console.error("opencascade.js can't parse this extension! (yet)");
//     }
//     // console.log("READER: ", reader, " FILETYPE: ", fileType);

//     if (reader) {
//       const readResult = reader.ReadFile(`file.${fileType}`); // Read the file
//       if (readResult === oc.IFSelect_ReturnStatus.IFSelect_RetDone) {
//         // console.log("file loaded successfully! Converting to OCC now...");
//         reader.TransferRoots(new oc.Message_ProgressRange_1()); // Translate all transferable roots to OpenCascade
//         const stepShape = reader.OneShape(); // Obtain the results of translation in one OCCT shape
//         const geometry = generateThreeBufferGeometry(oc, stepShape,singleMesh);

//         // Remove the file when we're done (otherwise we run into errors on reupload)
//         oc.FS.unlink(`/file.${fileType}`);

//         if (Array.isArray(geometry)) {
//           return geometry.map(g => generateMeshFromBufferGeometry(g))
//         }
//         return generateMeshFromBufferGeometry(geometry);
//       } else {
//         console.error("Something in OCCT went wrong trying to read ");
//       }
//     }
//     throw new Error("Something went wrong trying to read the file");
//   });
// };

// export const ConvertStepToThreeBufferGeometry = async (
//   oc: OpenCascadeInstance,
//   file: Blob,
//   singleMesh: boolean = false
// ): Promise<THREE.BufferGeometry | THREE.BufferGeometry[]> => {
//   return await loadFileAsync(file).then(async (fileText) => {
//     const fileType = "step";
//     // Writes the uploaded file to Emscripten's Virtual Filesystem
//     oc.FS.createDataFile("/", `file.${fileType}`, fileText, true, true, true);

//     // Choose the correct OpenCascade file parsers to read the CAD file
//     let reader = null;
//     if (fileType === "step") {
//       reader = new oc.STEPControl_Reader_1();
//     } else if (fileType === "iges") {
//       reader = new oc.IGESControl_Reader_1();
//     } else {
//       console.error("opencascade.js can't parse this extension! (yet)");
//     }
//     // console.log("READER: ", reader, " FILETYPE: ", fileType);

//     if (reader) {
//       const readResult = reader.ReadFile(`file.${fileType}`); // Read the file
//       if (readResult === oc.IFSelect_ReturnStatus.IFSelect_RetDone) {
//         // console.log("file loaded successfully! Converting to OCC now...");
//         reader.TransferRoots(new oc.Message_ProgressRange_1()); // Translate all transferable roots to OpenCascade
//         const stepShape = reader.OneShape(); // Obtain the results of translation in one OCCT shape
//         const geometry = generateThreeBufferGeometry(oc, stepShape,singleMesh);

//         // Remove the file when we're done (otherwise we run into errors on reupload)
//         oc.FS.unlink(`/file.${fileType}`);

//         if (Array.isArray(geometry)) {
//           return geometry
//         }
//         return geometry;
//       } else {
//         console.error("Something in OCCT went wrong trying to read ");
//       }
//     }
//     throw new Error("Something went wrong trying to read the file");
//   });
// };

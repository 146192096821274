import { BufferGeometry } from "three";
export function getNormalizationScaleFactor(
  geometry: BufferGeometry
): { scale: number; bbox: number[] } | null {
  if (!geometry.boundingBox) {
    geometry.computeBoundingBox();
  }
  const bbox = geometry.boundingBox;
  if (bbox) {
    const boundingBoxDims = [
      bbox.max.x - bbox.min.x,
      bbox.max.y - bbox.min.y,
      bbox.max.z - bbox.min.z,
    ];
    const max = Math.max(...boundingBoxDims);
    return { scale: 1 / max, bbox: boundingBoxDims };
  }

  return null;
}

import {
  GProp_GProps,
  OpenCascadeInstance,
  STEPControl_Reader_1,
  TColStd_SequenceOfAsciiString,
  TopoDS_Shape,
} from "opencascade.js";
import { BoundingBox, LengthUnit } from "./types";

export function getAxisAlignedBoundingBox(
  oc: OpenCascadeInstance,
  shape: TopoDS_Shape
): BoundingBox {
  // TODO: we should triangulate the faces ahead of time
  const bbox = new oc.Bnd_Box_1();
  oc.BRepBndLib.Add(shape, bbox, true);
  let xMin: number = 0;
  let yMin: number = 0;
  let zMin: number = 0;
  let xMax: number = 0;
  let yMax: number = 0;
  let zMax: number = 0;
  bbox.Get(xMin, yMin, zMin, xMax, yMax, zMax);
  const cmax = bbox.CornerMax();
  const cmin = bbox.CornerMin();
  const boundingBox: BoundingBox = {
    min: {
      x: cmin.X(),
      y: cmin.Y(),
      z: cmin.Z(),
    },
    max: {
      x: cmax.X(),
      y: cmax.Y(),
      z: cmax.Z(),
    },
  };
  return boundingBox;
}

export function getLengthUnit(
  reader: STEPControl_Reader_1,
  oc: OpenCascadeInstance
): LengthUnit {
  const lengthUnit: TColStd_SequenceOfAsciiString =
    new oc.TColStd_SequenceOfAsciiString_1();
  const angleUnit: TColStd_SequenceOfAsciiString =
    new oc.TColStd_SequenceOfAsciiString_1();
  const solidAngleUnit: TColStd_SequenceOfAsciiString =
    new oc.TColStd_SequenceOfAsciiString_1();
  reader.FileUnits(lengthUnit, angleUnit, solidAngleUnit);

  const lengthUnitString = lengthUnit.First().ToCString();
  if (!Object.values(LengthUnit).includes(lengthUnitString as any)) {
    throw new Error(`Unknown Length Unit Type: ${lengthUnitString}`);
  }
  return lengthUnitString as LengthUnit;
}

export function getBodyVolume(shape: TopoDS_Shape, oc: OpenCascadeInstance) {
  const massProps: GProp_GProps = new oc.GProp_GProps_1();

  // https://dev.opencascade.org/doc/refman/html/class_b_rep_g_prop.html#ad073145de616e80608bffc4652d59291
  oc.BRepGProp.VolumeProperties_1(shape, massProps, false, false, false);
  return massProps.Mass();
}

import { Line } from "@react-three/drei";

// R3F Implementation of Axes using Y-negative and allowing arbitrary placement

type XYZ = { x: number; y: number; z: number };
export function Axes(props: { placement: XYZ }) {
  const { placement } = props;
  const lineSegmentPoints = (
    placement: XYZ,
    delta: XYZ
  ): [number, number, number][] => {
    const { x: px, y: py, z: pz } = placement;
    const { x: dx, y: dy, z: dz } = delta;

    return [
      [px, py, pz],
      [px + dx, py + dy, pz + dz],
    ];
  };
  return (
    <group>
      <Line
        points={lineSegmentPoints(placement, { x: 1.25, y: 0, z: 0 })}
        color="red"
      />
      <Line
        points={lineSegmentPoints(placement, { x: 0, y: -1.25, z: 0 })}
        color="green"
      />
      <Line
        points={lineSegmentPoints(placement, { x: 0, y: 0, z: 1.25 })}
        color="blue"
      />
    </group>
  );
}

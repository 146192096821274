import { Button, Stack, TextField, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Decimal from "decimal.js";
import { noop } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useCADContextConsumer } from "../CAD/CADContext";
import { Stock } from "../CAD/Model";

import {
  abbreviateUnitString,
  convertBoundingBoxDimensionsToLocalUnits,
  convertDimLocalUnitsToMM,
  convertDimensionToLocalUnits,
  convertStockVolumeToLocalUnits,
} from "../CAD/unitsHelpers";
import { useOCCContextConsumer } from "../OCC/OCCContext";
import { LengthUnit } from "../OCC/types";

type StockOffset = {
  x: number;
  y: number;
  z: number;
};

function useStockSelector() {
  const { model, setStock, stock } = useCADContextConsumer();
  const [stockOffset, setStockOffset] = useState<StockOffset>(
    stock
      ? {
          x: stock.xOffset,
          y: stock.yOffset,
          z: stock.zOffset,
        }
      : {
          x: 0,
          y: 0,
          z: 0,
        }
  );

  const [tempStockOffset, setTempStockOffset] =
    useState<StockOffset>(stockOffset);

  if (!model) {
    throw new Error("CAD not defined");
  }

  const volume = stock
    ? convertStockVolumeToLocalUnits(stock.volume, model.lengthUnit)
    : undefined;

  const bboxInLocalUnits = stock
    ? convertBoundingBoxDimensionsToLocalUnits(stock.bbox, model.lengthUnit)
    : undefined;

  const xOffsetInLocalUnits = convertDimensionToLocalUnits(
    stockOffset.x,
    model.lengthUnit
  );

  const yOffsetInLocalUnits = convertDimensionToLocalUnits(
    stockOffset.y,
    model.lengthUnit
  );

  const zOffsetInLocalUnits = convertDimensionToLocalUnits(
    stockOffset.z,
    model.lengthUnit
  );

  return {
    model,
    stock,
    setStock,
    stockOffset,
    setStockOffset,
    tempStockOffset,
    setTempStockOffset,
    volume,
    bboxInLocalUnits,
    xOffsetInLocalUnits,
    yOffsetInLocalUnits,
    zOffsetInLocalUnits,
  };
}

export default function StockSelector(props: {
  showAddOrEditStock: (show: boolean) => void;
}) {
  const { showAddOrEditStock } = props;
  const { oc } = useOCCContextConsumer();
  // TODO: do I need to use a useMemo here?

  const {
    model,
    stock,
    setStock,
    stockOffset,
    setStockOffset,
    tempStockOffset,
    setTempStockOffset,
    volume,
    bboxInLocalUnits,
    xOffsetInLocalUnits,
    yOffsetInLocalUnits,
    zOffsetInLocalUnits,
  } = useStockSelector();

  useEffect(() => {
    //TODO: evaluate when this is firing
    //deletes old TopoDS_Shape and geometry
    stock?.cleanUp();
    setStock(
      new Stock(
        model.occShape,
        model.geometry,
        oc,
        tempStockOffset.x,
        tempStockOffset.y,
        tempStockOffset.z
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempStockOffset]);

  return (
    <div className="absolute top-32 left-8 z-50">
      <Card>
        <CardContent>
          <Stack spacing={3}>
            <Typography variant="h5" color="info">
              Stock Dimensions
            </Typography>
            <Stack direction="row" spacing={2}>
              <ReadOnlyDims value={bboxInLocalUnits?.x ?? 0} label={"X"} />
              <ReadOnlyDims value={bboxInLocalUnits?.y ?? 0} label={"Y"} />
              <ReadOnlyDims
                value={bboxInLocalUnits?.z ?? 0}
                label={"Z"}
                endText="in"
              />
            </Stack>
            <StockOffsetField
              field="x"
              value={xOffsetInLocalUnits}
              label="X Offset"
              stockOffset={tempStockOffset}
              setStockOffset={setTempStockOffset}
              lengthUnit={model.lengthUnit}
            />
            <StockOffsetField
              field="y"
              value={yOffsetInLocalUnits}
              label="Y Offset"
              stockOffset={tempStockOffset}
              setStockOffset={setTempStockOffset}
              lengthUnit={model.lengthUnit}
            />
            <StockOffsetField
              field="z"
              value={zOffsetInLocalUnits}
              label="Z Offset"
              stockOffset={tempStockOffset}
              setStockOffset={setTempStockOffset}
              lengthUnit={model.lengthUnit}
            />
            <Stack direction={"row"} spacing={3}>
              <Button
                className="w-full"
                variant="outlined"
                color="primary"
                onClick={() => {
                  if (!stock) {
                    setStock(undefined);
                  } else {
                    stock.cleanUp();
                    // Revert stock back to what it was before closing
                    setStock(
                      new Stock(
                        model.occShape,
                        model.geometry,
                        oc,
                        stockOffset.x,
                        stockOffset.y,
                        stockOffset.z
                      )
                    );
                  }
                  showAddOrEditStock(false);
                }}
              >
                {!stock ? "Cancel" : "Close"}
              </Button>
              <Button
                className="w-full"
                variant="contained"
                color="primary"
                onClick={() => {
                  showAddOrEditStock(false);
                }}
              >
                Save
              </Button>
            </Stack>
          </Stack>
          <input
            type="submit"
            style={{ display: "none" }}
            disabled
            onClick={noop}
          />
          {/* {stock && volume && bboxInLocalUnits && (
          <>
            <div className="pt-6">Volume</div>
            <div>{formatVolumeAsUnitString(volume, model.lengthUnit)}</div>
          </>
        )} */}
        </CardContent>
      </Card>
    </div>
  );
}

function ReadOnlyDims(props: {
  value: number;
  label: string;
  endText?: string;
}) {
  const { value, label, endText } = props;
  return (
    <TextField
      className="w-16"
      label={label}
      type="number"
      variant="standard"
      size="small"
      disabled
      value={new Decimal(value).toFixed(2)}
      InputProps={{
        endAdornment: endText ? (
          <div className="pb-1">{endText}</div>
        ) : undefined,
      }}
    />
  );
}

function useDebouncedStockUpdate(
  delay: number,
  lengthUnit: LengthUnit,
  field: string,
  stockOffset: StockOffset,
  setStockOffset: (stockOffset: StockOffset) => void
) {
  const timeoutRef = useRef<NodeJS.Timeout | undefined>();
  // Make this function a callback
  const debouncedUpdateFn = (formValue: number) => {
    if (timeoutRef.current) {
      console.log("clearing timeout");
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(
      () =>
        setStockOffset({
          ...stockOffset,
          [field]: convertDimLocalUnitsToMM(formValue as number, lengthUnit),
        }),
      delay
    );
  };

  const debouncedUpdateCallback = useCallback(debouncedUpdateFn, [
    delay,
    lengthUnit,
    field,
    stockOffset,
    setStockOffset,
  ]);

  return debouncedUpdateCallback;
}

function StockOffsetField(props: {
  value: number;
  field: "x" | "y" | "z";
  label: string;
  stockOffset: StockOffset;
  setStockOffset: (stockOffset: StockOffset) => void;
  lengthUnit: LengthUnit;
}) {
  const { field, value, label, stockOffset, setStockOffset, lengthUnit } =
    props;
  const [formValue, setFormValue] = useState<number | "">(value);

  const debouncedStockUpdate = useDebouncedStockUpdate(
    500,
    lengthUnit,
    field,
    stockOffset,
    setStockOffset
  );

  useEffect(() => {
    if (formValue !== "" && formValue !== value) {
      debouncedStockUpdate(formValue);
    }
  }, [value, formValue, setFormValue]);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Let the user clear the field, but make sure they are entering a number
    const updatedValue = event.target.value;
    if (
      updatedValue === "" ||
      (Number(updatedValue) >= 0 && updatedValue.match(/^[0-9]+(\.[0-9]*)?$/))
    ) {
      setFormValue(updatedValue === "" ? "" : Number(updatedValue));
    }
  };

  return (
    <TextField
      fullWidth
      inputProps={{
        step: 0.01,
      }}
      label={label}
      type="number"
      size="small"
      margin="dense"
      value={formValue}
      onBlur={() => {
        if (formValue === "") {
          setFormValue(new Decimal(0).toDP(2).toNumber());
        }
      }}
      onChange={handleOnChange}
      InputProps={{
        endAdornment: <> {abbreviateUnitString(lengthUnit)}</>,
      }}
    />
  );
}

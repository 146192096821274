// import { useGeometryFromLocalStep } from "./Three.Helpers";
import { useCADContextConsumer } from "../CAD/CADContext";
import FileUploadPage from "../Viewer/FileUpload";
import Scene from "../Viewer/Scene";
import Page from "./Page";

export default function QuoteTool() {
  const { model } = useCADContextConsumer();
  if (!model) {
    return (
      <Page>
        <FileUploadPage />
      </Page>
    );
  }
  return (
    <Page>
      <Scene />
    </Page>
  );
}

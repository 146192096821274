import { Add, Delete, Edit } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useCADContextConsumer } from "../CAD/CADContext";

export default function VerticalNav(props: {
  addOrEditStock: boolean;
  setAddOrEditStock: (addOrEditStock: boolean) => void;
}) {
  const { stock, clearStock } = useCADContextConsumer();
  const { addOrEditStock, setAddOrEditStock } = props;

  return (
    <>
      <div className="bg-white w-64 flex flex-col px-8 pt-12 shadow-xl gap-y-4">
        <Button
          variant="outlined"
          endIcon={stock ? <Edit /> : <Add />}
          disabled={addOrEditStock}
          onClick={() => setAddOrEditStock(true)}
        >
          {!stock ? <div>Add Stock</div> : <div>Edit Stock</div>}
        </Button>
        {stock && (
          <Button
            variant="outlined"
            // color="error"
            // Also set border color as error dark on hover
            sx={{
              color: "error.dark",
              borderColor: "error.dark",
              "&:hover": { borderColor: "error.dark" },
            }}
            endIcon={<Delete />}
            disabled={addOrEditStock}
            onClick={clearStock}
          >
            {"Remove Stock"}
          </Button>
        )}
      </div>
    </>
  );
}

import { BoundingBox, LengthUnit } from "../OCC/types";
import Decimal from "decimal.js";

export function convertStockVolumeToLocalUnits(
  volumeInMMCubed: number,
  lengthUnit: LengthUnit
) {
  switch (lengthUnit) {
    case LengthUnit.INCH:
      return volumeInMMCubed / 25.4 ** 3;
    case LengthUnit.MILLIMETER:
      return volumeInMMCubed;
  }
}

export function convertDimensionToLocalUnits(
  valueInMM: number,
  lengthUnit: LengthUnit
) {
  switch (lengthUnit) {
    case LengthUnit.INCH:
      return valueInMM / 25.4;
    case LengthUnit.MILLIMETER:
      return valueInMM;
  }
}

export function convertDimLocalUnitsToMM(
  valueInLocalUnit: number,
  lengthUnit: LengthUnit
) {
  switch (lengthUnit) {
    case LengthUnit.INCH:
      return valueInLocalUnit * 25.4;
    case LengthUnit.MILLIMETER:
      return valueInLocalUnit;
  }
}

export function convertBoundingBoxDimensionsToLocalUnits(
  bboxInMM: BoundingBox,
  lengthUnit: LengthUnit
): { x: number; y: number; z: number } {
  const bboxX = bboxInMM.max.x - bboxInMM.min.x;
  const bboxY = bboxInMM.max.y - bboxInMM.min.y;
  const bboxZ = bboxInMM.max.z - bboxInMM.min.z;
  switch (lengthUnit) {
    case LengthUnit.INCH:
      return { x: bboxX / 25.4, y: bboxY / 25.4, z: bboxZ / 25.4 };
    case LengthUnit.MILLIMETER:
      return { x: bboxX, y: bboxY, z: bboxZ };
  }
}

export function formatValueAsUnitString(
  value: number,
  lengthUnit: LengthUnit,
  precision: number = 2
) {
  const truncatedValue = new Decimal(value).toFixed(precision);
  switch (lengthUnit) {
    case LengthUnit.INCH:
      return truncatedValue + " IN";
    case LengthUnit.MILLIMETER:
      return truncatedValue + " MM";
  }
}

export function formatDecimal(value: number, precision: number = 2) {
  return new Decimal(value).toFixed(precision);
}

export function formatVolumeAsUnitString(
  volumeInMMCubed: number,
  lengthUnit: LengthUnit,
  precision: number = 2
) {
  const truncatedValue = new Decimal(volumeInMMCubed)
    .toFixed(precision);
    switch (lengthUnit) {
    case LengthUnit.INCH:
      return truncatedValue + " IN^3";
    case LengthUnit.MILLIMETER:
      return truncatedValue + " MM^3";
  }
}

export function abbreviateUnitString(lengthUnit: LengthUnit) {
  switch (lengthUnit) {
    case LengthUnit.INCH:
      return "in";
    case LengthUnit.MILLIMETER:
      return "mm";
  }
}

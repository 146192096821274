import { OpenCascadeInstance, TopoDS_Shape } from "opencascade.js";
import { getAxisAlignedBoundingBox } from "./helpers";

export function generateStockShape(
  shape: TopoDS_Shape,
  oc: OpenCascadeInstance,
  xOffset: number = 5,
  yOffset: number = 5,
  zOffset: number = 5
) {
  const bb = getAxisAlignedBoundingBox(oc, shape);
  const stockShape = new oc.BRepPrimAPI_MakeBox_4(
    new oc.gp_Pnt_3(
      bb.min.x - xOffset,
      bb.min.y - yOffset,
      bb.min.z - zOffset
    ),
    new oc.gp_Pnt_3(
      bb.max.x + xOffset,
      bb.max.y + yOffset,
      bb.max.z + zOffset
    )
  ).Shape();
  const progress = new oc.Message_ProgressRange_1();
  const boolShape = new oc.BRepAlgoAPI_Cut_3(stockShape, shape, progress);
  boolShape.Build(progress);
  const delta = boolShape.Shape();
  return delta;
}

import { Edges } from "@react-three/drei";
import { Stock } from "../CAD/Model";
import * as THREE from "three";

export function StockMaterial(props: { stock: Stock; showCAD: boolean }) {
  const { showCAD, stock } = props;
  const hovered = false;
  return (
    <mesh
      onPointerEnter={(e) => {
        e.stopPropagation();
      }}
      onPointerLeave={(e) => {
        e.stopPropagation();
      }}
      geometry={stock.geometry.geometry}
    >
      <meshStandardMaterial
        attach="material"
        color={"#8d9bab"}
        transparent
        side={THREE.FrontSide}
        opacity={0.20}
        roughness={1}
        metalness={0.05}
        depthTest={showCAD}
      />
      {showCAD && <Edges color="#8d9bab" />}
    </mesh>
  );
}

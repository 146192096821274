import { Add } from "@mui/icons-material";
import { Fab } from "@mui/material";
import { styled } from "@mui/material/styles";
import { BufferGeometry } from "three";
import { useCADContextConsumer } from "../CAD/CADContext";
import { Model } from "../CAD/Model";
import { useOCCContextConsumer } from "../OCC/OCCContext";
import { OCCConversionResponseType, readAndConvertFile } from "../OCC/fileIO";
import VerticalNav from "./VerticalNav";
import { useState } from "react";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function FileUploadPage() {
  const { oc } = useOCCContextConsumer();
  const { setModel } = useCADContextConsumer();
  const [addOrEditStock, setAddOrEditStock] = useState<boolean>(true);
  return (
    <div className="w-full h-full flex">
      <VerticalNav
        addOrEditStock={addOrEditStock}
        setAddOrEditStock={setAddOrEditStock}
      />
      <div className="w-full h-full flex justify-center items-center bg-[#E5E5E5]">
        <Fab
          component="label"
          color="secondary"
          aria-label="add"
          role={undefined}
          tabIndex={-1}
        >
          <Add />
          <VisuallyHiddenInput
            onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
              const file = e.target.files?.[0];
              file &&
                readAndConvertFile(oc, file, true).then(
                  (loadResponse: OCCConversionResponseType) => {
                    const {
                      geometry: geo,
                      occShape: shape,
                      lengthUnit,
                    } = loadResponse;
                    setModel(
                      new Model(
                        file,
                        geo as BufferGeometry,
                        shape,
                        lengthUnit,
                        oc
                      )
                    );
                  }
                );
            }}
            type="file"
          />
        </Fab>
      </div>
    </div>
  );
}

import { Stack, Typography } from "@mui/material";
import initOpenCascade, { OpenCascadeInstance } from "opencascade.js";
import { createContext, useContext, useEffect, useState } from "react";
import Page from "../Main/Page";

export type OCCContextType = {
  oc: OpenCascadeInstance | null;
} | null;

const OCCContext = createContext<OCCContextType>(null);
const useOCCContext = () => useContext(OCCContext);
export const useOCCContextConsumer = () => {
  const occContext = useOCCContext();
  if (!occContext || !occContext.oc) {
    throw new Error("CAD Context Not Found");
  }
  return {
    oc: occContext.oc,
  };
};

export const OCCContextProvider = (props: { children: React.ReactNode }) => {
  const [oc, setOc] = useState<OpenCascadeInstance | null>(null);
  useEffect(() => {
    initOpenCascade().then((oc) => {
      setTimeout(() => setOc(oc), 2000);
    });
  }, []);

  const occContext: OCCContextType = {
    oc,
  };

  const { children } = props;
  return (
    <OCCContext.Provider value={occContext}>
      {oc ? <>{children}</> : <LoadingPage />}
    </OCCContext.Provider>
  );
};

function LoadingPage() {
  return (
    <Page>
      <div className="flex h-full items-center justify-center">
        <Stack spacing={4} alignItems="center">
          <Typography variant="h2" color="primary">
            Loading ...
          </Typography>
        </Stack>
      </div>
    </Page>
  );
}

import { createTheme, ThemeProvider } from "@mui/material";
import React from "react";
import ReactDOM from "react-dom/client";
import GlobalCssPriority from "./CSS/GlobalCSSPriority";
import "./index.css";
import App from "./Main/App";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const theme = createTheme({
  palette: {
    primary: {
      main: "#546A82",
    },
    secondary: { main: "#D6C562" },
  },
  components: {
    MuiPopover: {
      defaultProps: {
        container: root as unknown as Element,
      },
    },
    MuiPopper: {
      defaultProps: {
        container: root as unknown as Element,
      },
    },
    MuiDialog: {
      defaultProps: {
        container: root as unknown as Element,
      },
    },
    MuiModal: {
      defaultProps: {
        container: root as unknown as Element,
      },
    },
  },
});

root.render(
  <React.StrictMode>
    <GlobalCssPriority>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </GlobalCssPriority>
  </React.StrictMode>
);

import {
  Center,
  Edges,
  GizmoHelper,
  OrbitControls,
  Resize,
  useHelper,
} from "@react-three/drei";
import { Canvas, useThree } from "@react-three/fiber";
import { useRef, useState } from "react";
import * as THREE from "three";
import { BufferGeometry, Mesh, NormalBufferAttributes } from "three/src/Three";
import { useCADContextConsumer } from "../CAD/CADContext";
import { Axes } from "../Three/Axes";
import { CustomGizmoViewport } from "../Three/CustomGizmoViewport";
import { StockMaterial } from "../Three/Stock";
import { ButtonOverlay } from "./ButtonOverlay";
import Lights from "./Lights";
import StockSelector from "./StockSelector";
import VerticalNav from "./VerticalNav";

// SETS the WCS to have Z as default up
THREE.Object3D.DEFAULT_UP.set(0, 0, 1);

const Scene = () => {
  const [showStock, setShowStock] = useState(true);
  const [showCAD, setShowCAD] = useState(true);
  const [addOrEditStock, setAddOrEditStock] = useState<boolean>(false);
  return (
    <div className="w-full h-full flex">
      <VerticalNav
        addOrEditStock={addOrEditStock}
        setAddOrEditStock={setAddOrEditStock}
      />
      <div className="w-full h-full relative">
        <ButtonOverlay
          showStock={showStock}
          setShowStock={setShowStock}
          showCAD={showCAD}
          setShowCAD={setShowCAD}
        />
        {addOrEditStock && (
          <StockSelector showAddOrEditStock={setAddOrEditStock} />
        )}
        <SceneInner showCAD={showCAD} showStock={showStock} />
      </div>
    </div>
  );
};

const SceneInner = (props: { showCAD: boolean; showStock: boolean }) => {
  const { showCAD, showStock } = props;

  return (
    <Canvas
      className="w-full h-full"
      shadows
      orthographic
      camera={{
        position: [4, -4, 4],
        zoom: 180,
        fov: 40,
      }}
    >
      <color attach="background" args={["#E5E5E5"]} />
      <OrbitControls makeDefault />
      <Lights />
      <GeometryAndStock showStock={showStock} showCAD={showCAD} />
      <GizmoHelper
        alignment="bottom-right" // widget alignment within scene
        margin={[120, 120]} // widget margins (X, Y)
      >
        <CustomGizmoViewport labelColor="white" />
      </GizmoHelper>
    </Canvas>
  );
};

function GeometryAndStock(props: { showCAD: boolean; showStock: boolean }) {
  const { showCAD, showStock } = props;
  const { model, stock } = useCADContextConsumer();
  const showModelBBox = false;
  const modelRef = useRef<Mesh<BufferGeometry<NormalBufferAttributes>> | null>(
    null
  );

  useHelper(showModelBBox && (modelRef as any), THREE.BoxHelper, "blue");
  const mainCamera = useThree((state) => state.camera);
  console.log(mainCamera.up);

  if (!model) {
    throw new Error("CAD Model not defined");
  }

  return (
    <>
      <Axes
        placement={{
          x: 0,
          y: 0,
          z: 0,
        }}
      />

      <Resize>
        <Center front bottom right>
          <group>
            {showCAD && (
              <mesh
                ref={modelRef}
                geometry={model.geometry.geometry}
                receiveShadow
                castShadow
              >
                <meshMatcapMaterial attach="material" color={"#8d9bab"} />
                {/* <meshMatcapMaterial color="gray" transparent opacity={0.5} /> */}
                {/* <ContactShadows
                  resolution={512}
                  position={[0, -0.01, 0]}
                  opacity={0.8}
                  scale={7}
                  blur={2}
                  far={0.8}
                /> */}
                <Edges color="black" />
              </mesh>
            )}

            {showStock && stock && (
              <StockMaterial showCAD={showCAD} stock={stock} />
            )}
          </group>
        </Center>
      </Resize>
    </>
  );
}

export default Scene;

import { createContext, useContext, useEffect, useState } from "react";
import { Model, Stock } from "./Model";

export type CADContextType = {
  model?: Model;
  setModel: (model: Model | undefined) => void;
  stock?: Stock;
  setStock: (stock: Stock | undefined) => void;
  clearAll: () => void;
  clearStock: () => void;
} | null;

const CADContext = createContext<CADContextType>(null);

const useCADContext = () => useContext(CADContext);

export const useCADContextConsumer = () => {
  const cadContext = useCADContext();
  if (!cadContext) {
    throw new Error("CAD Context Not Found");
  }
  return {
    ...cadContext,
  };
};

export const CADContextProvider = (props: { children: React.ReactNode }) => {
  const [model, setModel] = useState<Model>();
  const [stock, setStock] = useState<Stock>();

  const clearAll = () => {
    safeClear(model?.occShape.delete);
    safeClear(model?.geometry.geometry.dispose);
    setModel(undefined);
    safeClear(stock?.occShape.delete);
    safeClear(stock?.geometry.geometry.dispose);
    setStock(undefined);
  };

  const clearStock = () => {
    safeClear(stock?.occShape.delete);
    safeClear(stock?.geometry.geometry.dispose);
    setStock(undefined);
  }

  const cadContext: CADContextType = {
    model,
    setModel,
    stock,
    setStock,
    clearAll,
    clearStock,
  };

  // Cleanup in case of a page refresh
  useEffect(() => {
    return () => {
      clearAll();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { children } = props;
  return (
    <CADContext.Provider value={cadContext}>{children}</CADContext.Provider>
  );
};

export function safeClear(callback?: () => void) {
  if (!callback) return;  
  try {
    callback();
  } catch (e) {
    console.error(e);
  }
}

import { OpenCascadeInstance, TopoDS_Shape } from "opencascade.js";
import { BufferGeometry } from "three";
import convertShapeToThree from "./conversion"
import { LengthUnit } from "./types";
import { getLengthUnit } from "./helpers";

export type OCCConversionResponseType = {
  geometry: BufferGeometry | BufferGeometry[];
  occShape: TopoDS_Shape;
  lengthUnit: LengthUnit;
};

export function readFileToTopoDS_Shape(
    oc: OpenCascadeInstance,
    fileData: string,
    fileName: string
  ): {
    shape: TopoDS_Shape;
    lengthUnit: LengthUnit;
  } {
    let stepShape: TopoDS_Shape | undefined = undefined;
    let lengthUnit: LengthUnit | undefined;
  
    oc.FS.createDataFile("/", fileName, fileData, true, true, true);
    const reader = new oc.STEPControl_Reader_1();
    const readResult = reader.ReadFile(fileName); // Read the file
  
    if (readResult === oc.IFSelect_ReturnStatus.IFSelect_RetDone) {
      reader.TransferRoots(new oc.Message_ProgressRange_1()); // Translate all transferable roots to OpenCascade
  
      lengthUnit = getLengthUnit(reader, oc);
      stepShape = reader.OneShape(); // Obtain the results of translation in one OCCT shape
    }
  
    if (stepShape === undefined || lengthUnit === undefined) {
      throw new Error("Unable to process file");
    }
    oc.FS.unlink(fileName); // TODO: see if that actually fixes things
    return { shape: stepShape, lengthUnit };
  }

export async function readAndConvertFile(
  oc: OpenCascadeInstance,
  file: File,
  asSingleMesh: boolean
): Promise<OCCConversionResponseType> {
  const loadResponse: OCCConversionResponseType | undefined =
    await loadFileAsync(file).then(async (fileText: any) => {
      const { shape: stepShape, lengthUnit } = readFileToTopoDS_Shape(
        oc,
        fileText,
        file.name
      );
      if (stepShape) {
        const { geo: geometry, faceTypeList } = convertShapeToThree(
          oc,
          stepShape,
          asSingleMesh
        );
        return { geometry, occShape: stepShape, lengthUnit };
      } else {
        console.error(
          "Something in OCCT went wrong trying to read " + file.name
        );
      }
    });
  if (loadResponse === undefined) {
    throw new Error("Unable to process file");
  }
  return loadResponse;
}





function loadFileAsync(file: Blob): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsText(file);
    });
  }
  
  export async function loadBlobAsync(file: Blob): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsText(file);
    });
  }
  
  export async function loadLocalFileToBlobAsync(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }
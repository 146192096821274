import { OpenCascadeInstance, TopoDS_Shape } from "opencascade.js";
import { BufferGeometry } from "three";
import { generateStockShape } from "../OCC/stock";
import { getNormalizationScaleFactor } from "../Three/helpers";
import { BoundingBox, LengthUnit } from "../OCC/types";
import convertShapeToThree from "../OCC/conversion";
import { getAxisAlignedBoundingBox, getBodyVolume } from "../OCC/helpers";
import { safeClear } from "./CADContext";

export class Model {
  geometry: ModelGeometry;
  occShape: TopoDS_Shape;
  file: File;
  lengthUnit: LengthUnit;
  bbox: BoundingBox;
  volume: number; // in mm^3
  constructor(
    file: File,
    geometry: BufferGeometry,
    occShape: TopoDS_Shape,
    lengthUnit: LengthUnit,
    oc: OpenCascadeInstance
  ) {
    this.file = file;
    this.occShape = occShape;
    this.geometry = new ModelGeometry(geometry);
    this.lengthUnit = lengthUnit;
    this.bbox = getAxisAlignedBoundingBox(oc, occShape);
    this.volume = getBodyVolume(occShape, oc);
  }
}

export class ModelGeometry {
  geometry: BufferGeometry;
  bbox: number[] | null;
  scale: number | null;
  constructor(geometry: BufferGeometry) {
    this.geometry = geometry;
    const normalization = getNormalizationScaleFactor(geometry);
    if (normalization) {
      this.scale = normalization.scale;
      this.bbox = normalization.bbox;
    } else {
      this.scale = null;
      this.bbox = null;
    }
    if (this.scale && this.bbox) {
      this.geometry.scale(this.scale, this.scale, this.scale);
    }
  }
}

export class Stock {
  occShape: TopoDS_Shape;
  geometry: StockMaterialGeometry;
  volume: number;
  bbox: BoundingBox;
  xOffset: number;
  yOffset: number;
  zOffset: number;
  constructor(
    cadShape: TopoDS_Shape,
    cadGeo: ModelGeometry,
    oc: OpenCascadeInstance,
    xOffset: number,
    yOffset: number,
    zOffset: number
  ) {
    const stockShape = generateStockShape(
      cadShape,
      oc,
      xOffset,
      yOffset,
      zOffset
    );

    this.xOffset = xOffset;
    this.yOffset = yOffset;
    this.zOffset = zOffset;

    this.occShape = stockShape;

    const stockBufferGeo = convertShapeToThree(oc, stockShape, true).geo;
    this.geometry = new StockMaterialGeometry(
      stockBufferGeo as BufferGeometry,
      cadGeo.scale ?? 1,
      cadGeo.bbox ?? [1, 1, 1]
    );

    this.bbox = getAxisAlignedBoundingBox(oc, stockShape);
    this.volume = getBodyVolume(stockShape, oc);
  }

  cleanUp() {
    safeClear(this.occShape?.delete);
    safeClear(this.geometry?.geometry?.dispose);
  }
}

export class StockMaterialGeometry {
  listOfOperations: any[] = [];
  geometry: BufferGeometry;
  constructor(
    stockGeo: BufferGeometry,
    cadGeoScale: number,
    cadBBox: number[] | null
  ) {
    this.geometry = stockGeo;
    this.geometry.scale(cadGeoScale, cadGeoScale, cadGeoScale);

    if (!cadBBox) {
      throw new Error("No bounding box");
    }
  }
}

export const generateStock = (
  cadShape: TopoDS_Shape,
  cadGeo: ModelGeometry,
  oc: OpenCascadeInstance,
  xyOffset: number,
  zOffset: number
) => {
  const shape = generateStockShape(cadShape, oc, xyOffset, zOffset);
  const stockBufferGeo = convertShapeToThree(oc, shape, true).geo;
  const stockGeo = new StockMaterialGeometry(
    stockBufferGeo as BufferGeometry,
    cadGeo.scale ?? 1,
    cadGeo.bbox ?? [1, 1, 1]
  );
  return stockGeo;
};

import { CADContextProvider } from "../CAD/CADContext";
import { OCCContextProvider } from "../OCC/OCCContext";
import QuoteTool from "./QuoteTool";

function App() {

  return (
    <div className="h-screen w-screen flex items-center justify-center bg-[#E5E5E5]">
      <OCCContextProvider>
        <CADContextProvider>
          <QuoteTool/>
        </CADContextProvider>
        </OCCContextProvider>
    </div>
  );
}

export default App;

export enum LengthUnit {
  INCH = "inch",
  MILLIMETER = "millimetre",
}

export enum SurfaceTypeEnum {
  GeomAbs_Plane,
  GeomAbs_Cylinder,
  GeomAbs_Cone,
  GeomAbs_Sphere,
  GeomAbs_Torus,
  GeomAbs_BezierSurface,
  GeomAbs_BSplineSurface,
  GeomAbs_SurfaceOfRevolution,
  GeomAbs_SurfaceOfExtrusion,
  GeomAbs_OffsetSurface,
  GeomAbs_OtherSurface,
}

export type BoundingBox = {
  min: {
    x: number;
    y: number;
    z: number;
  };
  max: {
    x: number;
    y: number;
    z: number;
  };
}
import DeleteIcon from "@mui/icons-material/Delete";
import { Button } from "@mui/material";
import { useCADContextConsumer } from "../CAD/CADContext";

export const ButtonOverlay = (props: {
  showStock: boolean;
  setShowStock: (show: boolean) => void;
  showCAD: boolean;
  setShowCAD: (show: boolean) => void;
}) => {
  const { clearAll, stock } = useCADContextConsumer();
  const { showStock, setShowStock, showCAD, setShowCAD } = props;
  return (
    <>
      <div className="absolute left-8 top-12 z-50 text-slate-400">
        <div className="flex gap-x-4">
          <Button
            variant="contained"
            aria-label="delete"
            sx={{
              backgroundColor: "error.light",
              "&:hover": {
                backgroundColor: "error.dark",
              },
            }}
            onClick={clearAll}
          >
            <DeleteIcon />
          </Button>
          <Button
            sx={{ backgroundColor: "primary.light" }}
            variant="contained"
            onClick={() => setShowCAD(!showCAD)}
          >
            {showCAD ? "Hide CAD" : "Show CAD"}
          </Button>
          {stock && (
            <Button
              variant="contained"
              onClick={() => setShowStock(!showStock)}
              sx={{ backgroundColor: "primary.light" }}
            >
              {showStock ? "Hide Stock" : "Show Stock"}
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

import { useHelper } from "@react-three/drei";
import { useRef } from "react";
import { DirectionalLightHelper, Object3D, SpotLightHelper } from "three";

export default function Lights(props: { helper?: boolean }) {
  const { helper = false } = props;
  return (
    <>
      <ambientLight intensity={0.3} />
    </>
  );
}

export function DirLight(props: {
  position: [number, number, number];
  intensity: number;
  helper?: boolean;
}) {
  const { position, intensity, helper } = props;
  const dirLight = useRef<any>();
  const targetRef = useRef(new Object3D());
  useHelper(dirLight, DirectionalLightHelper, 1, "red");
  return (
    <>
      <primitive object={targetRef.current} position={[0.5, -0.5, 0]} />
      <directionalLight
        target={targetRef.current}
        shadow-mapSize-height={512}
        shadow-mapSize-width={512}
        castShadow
        visible={true}
        ref={helper ? dirLight : undefined}
        intensity={intensity}
        position={position}
      />
    </>
  );
}

export function SpotLightWrapper(props: {
  helper?: boolean;
  position: [number, number, number];
  target: [number, number, number];
}) {
  const { position, target, helper } = props;
  const spotLight = useRef<any>();
  useHelper(spotLight, SpotLightHelper, 1);
  return (
    <spotLight
      ref={helper ? spotLight : undefined}
      penumbra={1}
      intensity={1}
      decay={2}
      angle={0.5}
      shadow-mapSize-height={512}
      shadow-mapSize-width={512}
      castShadow
      visible={true}
      target-position={target}
      position={position}
    />
  );
}

export function SpotLights(props: { helper?: boolean }) {
  const { helper } = props;
  //   const helper = false;
  return (
    <>
      <SpotLightWrapper
        helper={helper}
        target={[0.83, -0.33, 0]}
        position={[1.25, 0.25, 3]}
      />
      <SpotLightWrapper
        helper={helper}
        target={[0.33, -0.33, 0]}
        position={[-0.25, 0.25, 3]}
      />
      <SpotLightWrapper
        helper={helper}
        target={[0.5, -0.83, 0]}
        position={[0.5, -1.25, 3]}
      />
      <SpotLightWrapper
        helper={helper}
        target={[0.83, -0.33, 0]}
        position={[1.25, 0.25, -3]}
      />
      <SpotLightWrapper
        helper={helper}
        target={[0.33, -0.33, 0]}
        position={[-0.25, 0.25, -3]}
      />
      <SpotLightWrapper
        helper={helper}
        target={[0.5, -0.83, 0]}
        position={[0.5, -1.25, -3]}
      />
    </>
  );
}
